<template>


  <div class="info"  v-if="props.data">
     
    <!-- <BrandDesc
      :info="props.data.info"
      :nm="props.data.name"
      :nmalt="props.data.name"
      :avatar="props.data.avatar_small"
      :avatar-ratio="'free'"
      :items_photo="props.data.items_photo"
      />  -->
      

    <!-- <BrandProductSetup v-if="props.data.item_product_setup"
      :type="seed"
      :list="props.data.item_product_setup"
      /> -->
      

    <!-- <div class="cpm"></div> -->
     
    <!-- 
      <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('breeder_tab_info_awards')"
      :showmore="$t('product_view_diaries_show_all')"      
      :list="props.data.items_diary.filter(b => {return b.group_name === 'win'})"
      :cnt="props.data.cnt_reports"
      :link="$route.path + '/diaries'"
      />    
    -->


    <BrandSimilarProductsBig
      :title="props.data.name + ' Breeder products'"   
      :list="props.data.items_product" 
      type="product"
      :show-brand="true"
      />
      <br>

      
    <BrandTreeHorizontal
      :title="$t('strain_view_subtitle_family_tree')"   
      :tree="props.data.tree" 
      />
      <br>
     
    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('strain_view_popular_diaries')"
      :showmore="$t('strain_view_show_all_diaries')"      
      :list="props.data.items_diary.filter(b => {return b.group_name === 'top'})"
      :cnt="props.data.item_stat?.cnt_reports"
      :link="$route.path + '/diaries'"
      />   

      <br>
    
    <!-- <BrandShorts  
      :title="'Shorts'"
      :showmore="'Show all'"
      :tag="'pr:' + 117 + ':' + 'fastbuds'"
      /> -->

    <!-- <div class="cpm"></div> -->
 
    <br>
       
     
    <BrandDiaries v-if="props.data.items_diary && props.data.items_diary.length"
      :title="$t('strain_view_subtitle_new_diaries')"
      :showmore="$t('strain_view_show_all_diaries')"      
      :list="props.data.items_diary.filter(b => {return b.group_name === 'new'})"
      :cnt="props.data.item_stat?.cnt_reports"
      :link="$route.path + '/diaries'"
      />
      <br>
     
    <BrandHarvestItems
      :title="$t('strain_view_subtitle_latest_harvests')"
      :showmore="$t('breeder_view_show_all_harvests')"     
      :link="$route.path + '/harvests'" 
      :list="props.data.items_harvest"
      :cnt="props.data.item_stat?.cnt_harvests"
      />    
      <br>
     
    <BrandReviewItems
      :title="$t('strain_view_latest_reviews')"
      :showmore="$t('breeder_view_show_all_reviews')"      
      :link="$route.path + '/reviews'"  
      :list="props.data.items_review"
      :cnt="props.data.item_stat?.cnt_reviews"
      />    
      

    <CommentWidget v-if="props.data.id"
      :title="$t('strain_view_subtitle_latest_comments')"
      :type="'product'"
      :content="props.data.id"
      />



  </div>
</template>


<script setup>

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const productsCurrentYear = computed(() => {
  return getProductsList(new Date().getFullYear());
})
const productsYearAgo = computed(() => {
  return getProductsList(new Date().getFullYear()-1);
})
const productsOld = computed(() => {
  return getProductsList('old');
})

const getProductsList = (year) => {
  var arr = [];      
  if(props.data.items)
    for(var item of props.data.items)
      if(year == item.group_name)
        arr.push(item);        
  return arr;
}

useHead(useNuxtApp().$head.getStrainInfo(props.data));

</script>

  

<style scoped>


.add_filter_box{
  display: flex;
  margin-left: auto;
  align-self: baseline;
}
.flow_boxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

</style>
